<template>
  <div class="card h-100">
    <div class="h4 font-bolder text-danger asdasd px-2 pt-2">
      สรุปรายวัน (KRW)
    </div>

    <div class="mt-n75 px-2 pb-1">
      <small class="text-muted">
        ยอดผู้เล่นจะอัพเดททุก 00:00 AM (เที่ยงคืน)
      </small>
    </div>

    <div class="d-block d-md-none px-1 py-25">
      <div class="card-mobile">
        <div class="d-flex align-items-center justify-content-between">
          <span class="card__actv">
            ผู้เล่น {{ data ? data.activeToday : 0 }}
          </span>

          <small class="text-black">
            {{ today }}
          </small>
        </div>

        <div
          class="d-flex pt-50 pb-25"
          style="gap: 10px"
        >
          <div class="item__f1 w-3h p-50">
            <div class="text-muted small">
              ยอดฝาก
            </div>

            <div class="text-premier font-weight-bolder font-medium-2">
              {{
                data && data.todayDep
                  ? data.todayDep
                    .reduce((total, item) => total + Number(item.amount), 0)
                    .toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0
              }}
            </div>
          </div>

          <div class="item__f1 w-3h p-50">
            <div class="text-muted small">
              ยอดถอน
            </div>

            <div class="text-danger font-weight-bolder font-medium-2">
              {{
                data && data.todayWit
                  ? data.todayWit
                    .reduce((total, item) => total + Number(item.amount), 0)
                    .toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0
              }}
            </div>
          </div>

          <div class="item__f1 w-3h p-50">
            <div class="text-muted small">
              กำไรสุทธิ
            </div>
            <div
              :class="{
                'text-success':
                  data && data.todayDep && data.todayWit
                    ? data.todayDep.reduce(
                      (total, item) => total + Number(item.amount),
                      0
                    ) -
                      data.todayWit.reduce(
                        (total, item) => total + Number(item.amount),
                        0
                      ) >=
                      0
                    : false,
                'text-danger':
                  data && data.todayDep && data.todayWit
                    ? data.todayDep.reduce(
                      (total, item) => total + Number(item.amount),
                      0
                    ) -
                      data.todayWit.reduce(
                        (total, item) => total + Number(item.amount),
                        0
                      ) <
                      0
                    : false,
              }"
              class="text-premier font-weight-bolder font-medium-2"
            >
              {{
                data && data.todayDep && data.todayWit
                  ? (
                    data.todayDep.reduce(
                      (total, item) => total + Number(item.amount),
                      0
                    ) -
                    data.todayWit.reduce(
                      (total, item) => total + Number(item.amount),
                      0
                    )
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  : (0.0).toFixed(2)
              }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(item, index) in reportDay"
        :key="index"
        class="card-mobile"
      >
        <div class="d-flex align-items-center justify-content-between">
          <span class="card__actv"> ผู้เล่น {{ item.active }} </span>

          <small class="text-black">
            {{ item.day + "-" + item.month + "-" + item.year }}
          </small>
        </div>

        <div
          class="d-flex pt-50 pb-25"
          style="gap: 10px"
        >
          <div class="item__f1 w-3h p-50">
            <div class="text-muted small">
              ยอดฝาก
            </div>

            <div class="text-premier font-weight-bolder font-medium-2">
              {{ item.deposit }}
            </div>
          </div>

          <div class="item__f1 w-3h p-50">
            <div class="text-muted small">
              ยอดถอน
            </div>

            <div class="text-danger font-weight-bolder font-medium-2">
              {{ item.withdraw }}
            </div>
          </div>

          <div class="item__f1 w-3h p-50">
            <div class="text-muted small">
              กำไรสุทธิ
            </div>
            <div
              :class="{
                'text-success': item.total >= 0,
                'text-danger': item.total < 0,
              }"
              class="text-premier font-weight-bolder font-medium-2"
            >
              {{ item.total }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive d-none d-md-block">
      <table class="table b-table w-full table-striped table-hover table-fixed">
        <thead>
          <tr>
            <th>วันที่</th>
            <th>ยอดสมัคร</th>
            <th>สมัครและฝาก</th>
            <th>active</th>
            <th>ยอดฝาก</th>
            <th>ยอดฝากแรก</th>
            <th>ค่าเฉลี่ยฝากแรก(วอน)</th>
            <th>ค่าเฉลี่ยฝากแรก(%)</th>
            <th>ยอดถอน</th>
            <th>กำไรสุทธิ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ today }}</td>
            <td>
              {{
                data
                  ? data.todayRegisterMembersKRW.total.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })
                  : 0
              }}
            </td>
            <td>
              {{ data ? data.depfirst_count_kr : 0 }}
            </td>
            <td>{{ data ? data.activeToday : 0 }}</td>
            <td>
              {{
                data && data.todayDepKR
                  ? data.todayDepKR
                    .reduce((total, item) => total + Number(item.amount), 0)
                    .toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0
              }}
            </td>
            <td>
              {{
                data
                  ? data.depfirsts_amount_kr.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })
                  : 0
              }}
            </td>
            <td>
              {{
                data.depfirsts_amount_kr
                  ? (
                    data.depfirsts_amount_kr / data.depfirst_count_kr
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  : 0
              }}
            </td>
            <td>
              {{
                data.depfirst_count_kr > 0
                  ? (
                    (data.depfirst_count_kr * 100) /
                    data.todayRegisterMembers.total
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })
                  : 0
              }}%
            </td>
            <td>
              {{
                data && data.todayWitKR
                  ? data.todayWitKR
                    .reduce((total, item) => total + Number(item.amount), 0)
                    .toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0
              }}
            </td>
            <td>
              {{
                data && data.todayDepKR && data.todayWitKR
                  ? (
                    data.todayDepKR.reduce(
                      (total, item) => total + Number(item.amount),
                      0
                    ) -
                    data.todayWitKR.reduce(
                      (total, item) => total + Number(item.amount),
                      0
                    )
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  : (0.0).toFixed(2)
              }}
            </td>
          </tr>
          <tr
            v-for="(item, index) in reportDay"
            :key="index"
          >
            <td>{{ item.created_at | date }}</td>
            <td>{{ item.regis_kr ? item.regis_kr: 0 }}</td>
            <td>
              {{ item.regisdep_kr ? item.regisdep_kr : 0 }}
            </td>
            <td>{{ item.active }}</td>
            <td>
              {{ item.deposit_kr ? item.deposit_kr.toLocaleString() : 0 }}
            </td>
            <td>
              {{ item.depfirst_sum_kr ? item.depfirst_sum_kr.toLocaleString() : 0 }}
            </td>
            <td>
              {{
                item.depfirst_sum_kr && item.depfirst_count_kr
                  ? (item.depfirst_sum_kr / item.depfirst_count_kr).toLocaleString(
                    undefined,
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  )
                  : 0
              }}
            </td>
            <td>
              {{
                item.depfirst_count_kr && item.regis_kr
                  ? ((item.depfirst_count_kr * 100) / item.regis_kr).toLocaleString(
                    undefined,
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )
                  : 0
              }} %
            </td>
            <td>
              {{ item.withdraw_kr }}
            </td>
            <td
              :class="{
                'text-success': item.total_kr >= 0,
                'text-danger': item.total_kr < 0,
              }"
            >
              {{ item.total_kr }}
            </td>
          </tr>
        </tbody>
        <tfoot style="background-color: #f3f2f7">
          <tr>
            <td colspan="9">
              กำไรสุทธิ
            </td>
            <td
              class="font-weight-bold"
              :class="{
                'text-success': profitDays >= 0,
                'text-danger': profitDays < 0,
              }"
            >
              {{ profitDays.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
  },
  filters: {
    date(val) {
      return moment(val).utc().subtract(-1).format('DD-MM-YYYY')
    },
  },
  props: ['reportDay', 'profitDays', 'data'],
  data() {
    return {
      // reportDay: [],
      // reportYear: [],
      // profitDays: '',
      fields: [
        {
          key: 'created_at',
          label: 'วันที่',
          formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'active', label: 'ยอดผู้เล่น' },
        { key: 'deposit', label: 'ยอดฝาก' },
        { key: 'withdraw', label: 'ยอดถอน' },
        { key: 'total', label: 'กำไรสุทธิ' },
        { key: 'profitDay', label: 'กำไรสุทธิ' },
      ],
      today: moment().tz('Asia/Bangkok').format('DD-MM-YYYY'),
    }
  },
  mounted() {
    console.log(this.data)
    // this.getDay()
  },
  methods: {
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getDay() {
      this.$http
        .get('dashboard/ReportDaysAndYears')
        .then(response => {
          this.reportDay = response.data.days
          this.profitDays = response.data.profitDay
          // console.log(response.data.days)
          this.reportYear = response.data.years
          // console.log(this.reportYear)
        })
        .catch(error => console.log(error))
    },
    getTotalSum(monthData, field) {
      return monthData.reduce((total, item) => total + item[field], 0)
    },
    getTotalSumForAllMonths(field) {
      let totalSum = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.reportYear) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.reportYear.hasOwnProperty(key)) {
          totalSum += this.getTotalSum(this.reportYear[key], field)
        }
      }
      return totalSum
    },
    fmDate(value) {
      return moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')
    },
    getNumberClass(number) {
      return {
        'text-success': number > 0,
        'text-danger': number < 0,
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
