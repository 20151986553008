var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-transaction h-100",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v("สรุปผลของ ยอดฝาก/ยอดถอน/โบนัส")]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MoreVerticalIcon","size":"18"}})],1),_c('b-card-body',[_vm._l((_vm.transactionData),function(transaction){return _c('div',{key:transaction.mode,staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-avatar',{attrs:{"rounded":"","size":"42","variant":transaction.avatarVariant}},[_c('feather-icon',{attrs:{"size":"18","icon":transaction.icon}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title mb-0"},[_vm._v(" "+_vm._s(transaction.title)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(transaction.en))])])],1),_c('div',{staticClass:"font-weight-bolder",class:transaction.title === 'ฝากเงิน'
            ? 'text-success'
            : transaction.title === 'ถอนเงิน'
              ? 'text-danger'
              : transaction.title === 'โบนัส'
                ? 'text-info'
                : 'text-warning',staticStyle:{"font-size":"1.3rem"}},[_vm._v(" "+_vm._s(transaction.amount ? transaction.amount.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : 0)+" "),_c('span',{staticStyle:{"font-size":"0.95rem"}},[_vm._v("THB")])])],1)}),_c('hr'),_vm._l((_vm.transactionDataKR),function(transaction){return _c('div',{key:transaction.mode,staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-avatar',{attrs:{"rounded":"","size":"42","variant":transaction.avatarVariant}},[_c('feather-icon',{attrs:{"size":"18","icon":transaction.icon}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title mb-0"},[_vm._v(" "+_vm._s(transaction.title)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(transaction.en))])])],1),_c('div',{staticClass:"font-weight-bolder",class:transaction.title === 'ฝากเงิน'
            ? 'text-success'
            : transaction.title === 'ถอนเงิน'
              ? 'text-danger'
              : transaction.title === 'โบนัส'
                ? 'text-info'
                : 'text-warning',staticStyle:{"font-size":"1.3rem"}},[_vm._v(" "+_vm._s(transaction.amount ? transaction.amount.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : 0)+" "),_c('span',{staticStyle:{"font-size":"0.95rem"}},[_vm._v("KRW")])])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }